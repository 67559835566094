import PortalClienteService from "services/bases/PortalClienteService";

class PedidoCompraService extends PortalClienteService{
    async CalcularNovoValor(tenantId, pedidosLicenca){
        return await this.post(`pedidoCompra/CalcularNovoValor?tenantId=${tenantId}`,pedidosLicenca)
    }
    async EfetuarCompraLicencas(tenantId, pedidoCompra){
        return await this.post(`pedidoCompra/Confirmar?tenantId=${tenantId}`,pedidoCompra)
    }
}

export default new PedidoCompraService();