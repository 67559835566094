import PortalClienteService from "services/bases/PortalClienteService";

class OcorrenciasPortalService extends PortalClienteService{
    async ListarTarefas(ocorrenciaId){
        return await this.get(`ocorrencias/ListarTarefas/${ocorrenciaId}`)
    }
    async BuscarOcorrencia(ocorrenciaId){
        return await this.get(`ocorrencias/${ocorrenciaId}`)
    }
    async CriarSolicitacao(data){
        const updatedData = { ...data, origemCodigo: '3' };
        return await this.post(`ocorrencias/CriarSolicitacao`, JSON.stringify(updatedData))
    }
    async AtualizarBoletoFaturamento(data){
        const updatedData = { ...data, origemCodigo: '3' };
        return await this.post(`ocorrencias/CriarSolicitacaoAtualizarBoletoFaturamento`, JSON.stringify(updatedData))
    }
    async BaixarVariosArquivosFaturamento(data){
        const updatedData = { ...data, origemCodigo: '3' };
        return await this.post(`ocorrencias/CriarSolicitacaoBaixarVariosArquivosFaturamento`, JSON.stringify(updatedData))
    }
}

export default new OcorrenciasPortalService();