import React, { Component } from 'react';

import ContentBox from "../../../../components/ContentBox"
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';

export default class Checkout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      licenses,
      totalMensal,
      newLicensesValue,
      checkoutOption,
      loading,
      companies,
      acceptedTerms,
      acceptTerms,
      handleCurrencyFormat,
      tiposProduto,
      policy
    } = this.props;

    const currencySymbol = licenses.length ? licenses[0].produto.moeda.nome : '$';

    if (loading) {
      return (
        <div className="small-loading">
          <div className="simple-loading"></div>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <ContentBox className="list-container">
            <table className="checkout-list">
              <thead>
                {
                  checkoutOption === 0 && (
                    <tr key="Assinatura">
                      <th className="align-left">Assinatura</th>
                      <th>Quantidade</th>
                      <th>Preço Unitário</th>
                      <th>Preço Total</th>
                    </tr>
                  )
                }

                {
                  checkoutOption === 1 && (
                    <tr key="Empresa_Assinatura">
                      <th className="align-left">Empresa/Assinatura</th>
                      <th>Quantidade</th>
                      <th>Preço Unitário</th>
                      <th>Preço Total</th>
                    </tr>
                  )
                }
              </thead>

              <tbody>
                  {
                    checkoutOption === 0 && licenses.map(license => (
                      <React.Fragment>
                        <tr key={license.assinaturaId ? license.assinaturaId : license.produto.produtoId}>
                          <th className="align-left">{license.produto.nome}</th>
                          <th>{license.quantidade}</th>
                          <th>
                            {!!license.precoUnitario ?
                            handleCurrencyFormat(license.precoUnitario, currencySymbol) :
                            handleCurrencyFormat(0, currencySymbol) }
                          </th>
                          <th>
                            {!!license.precoUnitario ?
                            handleCurrencyFormat(license.precoUnitario * license.quantidade, currencySymbol) :
                            handleCurrencyFormat(0, currencySymbol) }
                          </th>
                        </tr>
                      </React.Fragment>
                    ))
                  }

                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th><strong>{totalMensal}</strong></th>
                </tr>
              </tbody>
            </table>

            <div className="mensal-price">
              <p>Alteração de valor na fatura: <strong>{handleCurrencyFormat(newLicensesValue, currencySymbol)}</strong></p>
            </div>
          </ContentBox>
          {licenses[0]?.produto?.tipoProduto == CONSTANTES_AMBIENTE.PRODUTO_TIPOS_PRODUTOS.ONLINESERVICESNCE && (
            <ContentBox>
              <div>{policy.title}</div>
              <div dangerouslySetInnerHTML={{ __html: policy.content }}></div>

              <label htmlFor="acceptPolices" style={{cursor: 'pointer'}}>
                <input id="acceptPolices" checked={acceptedTerms} type="checkbox" onChange={() => acceptTerms()} />
                <span> Eu li e concordo com os termos da <strong>Política de licenciamento Microsoft da BHS</strong></span>
              </label>
            </ContentBox>
          )}
        </React.Fragment>
      )
    }
  }
}
