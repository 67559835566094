import React, {Component } from 'react';
import { NotificationManager } from "react-notifications";
import CustomQuantity from './form/CustomQuantity';
import ModalCancelarAssinatura from '../../ModalCancelarAssinatura';
import ModalAssinaturaForaDoPrazo from '../../ModalAssinaturaForaDoPrazo';
import ModalConfirmarCancelamentoAssinatura from '../../ModalConfirmarCancelamentoAssinatura';
import ModalTermoCancelamento from '../../ModalTermoCancelamento';
import policiesObject from '../components/termoCancelamentoAssinatura.json';
import postCancelarAssinatura from '../../../actions/postCancelarAssinatura/postCancelarAssinatura';
import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import { isTenantLegacy } from 'EstruturaAntiga/router/routerHelpers';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';

export default class Items extends Component {
  constructor(props) {
    super(props);

    this.state = {
      closeModalAssinaturaForaDoPrazo: true,
      closeModalCancelarAssinatura: true,
      closeModalTermoCancelarAssinatura: true,
      closeModalConfirmarCancelamentoAssinatura: true
    }
  }
  
  onClickCancelarModalAssinatura = () => {
		this.setState({ closeModalCancelarAssinatura: true });
		this.setState({ closeModalTermoCancelarAssinatura: true });
		this.setState({ closeModalConfirmarCancelamentoAssinatura: true });
		this.setState({ closeModalAssinaturaForaDoPrazo: true });
	}
  
  onConfirmCancelarAssinatura = () => {
    this.setState({ closeModalCancelarAssinatura: true });
		this.setState({ closeModalTermoCancelarAssinatura: false });
		this.setState({ closeModalAssinaturaForaDoPrazo: true });
	}
  
  onConfirmConfirmarCancelamentoAssinatura = () => {
    this.setState({ closeModalConfirmarCancelamentoAssinatura: false });
	}

  setCheckedPolicy = (value) => {
		this.setState({ checkedPolicy: value });
	}

  onCloseModal = () => {
		this.setState({ checkedPolicy: false });
    this.onClickCancelarModalAssinatura();
	}
  
  onSubmitCancelarAssinatura = async (cancelarAssinaturaData) => {
    const { license } = this.props;
    this.setState({ loadingModal: true });
	  let userSignature = HttpRequest.userSignature();
    cancelarAssinaturaData.AssinaturaCspCode = license.assinaturaCodigo;
    cancelarAssinaturaData.AssinaturaCspId = license.assinaturaId;
    cancelarAssinaturaData.AssinaturaNome = license.nome;
    cancelarAssinaturaData.dataLimiteCancelamento = license.dataLimiteCancelamento;
    cancelarAssinaturaData.TenantCode = userSignature.tenantCode;
    
    await postCancelarAssinatura(cancelarAssinaturaData)
        .then((response) => {       
            NotificationManager.success(response?.mensagem, response?.titulo, 7000);
            this.onConfirmConfirmarCancelamentoAssinatura();
            window.location.reload(true);
        })
        .catch((error) => {
            NotificationManager.error(
              error.response?.data?.mensagem ?? 'Erro inesperado ao tentar cancelar a assinatura. Favor entrar em contato com o nosso atendimento.',
              error.response?.data?.titulo ?? 'Cancelar Assinatura',
              5000);
        })
        .finally(() => {
          this.onCloseModal();
          this.setState({ loadingModal: false });
        });
  }

  onClickCancelarAssinatura = () =>{
    const { closeModalCancelarAssinatura, closeModalAssinaturaForaDoPrazo } = this.state; 
    const { license } = this.props;
    var dataAtual = new Date();
    if(license.dataLimiteCancelamento){
      var dataFinalCancelamento = new Date(license.dataLimiteCancelamento);
      if(dataAtual > dataFinalCancelamento)
          this.setState({ closeModalAssinaturaForaDoPrazo : false });
      else
          this.setState({ closeModalCancelarAssinatura : false });
    }
    else
      this.setState({ closeModalAssinaturaForaDoPrazo : false });
  }


  msgAlertQuantityEmpty = () => {
    return (
      <span>
        É necessário manter ao menos uma licença!
        <br/><br/>
        Você só pode reduzir a quantidade de licenças dentro da janela de cancelamento ou na renovação da assinatura/plano.
        <br/><br/>
        Se deseja cancelar a assinatura, acesse o menu de cancelamento.
      </span>
    )
  }

  msgAlertQuantityReduction = () => {
    return (
      <span>
        De acordo com a política de licenciamento da Microsoft não é possível reduzir a quantidade de licenças durante a vigência da assinatura, apenas nos primeiros 7 dias corridos a partir do provisionamento da assinatura ou na renovação.
        <br/><br/>
        Se a vigência da sua assinatura for mensal, a redução solicitada será agendada para a data da renovação.
        <br/><br/>
        Se a vigência da sua assinatura for anual, nosso time entrará em contato com você antes da renovação.
        <br/><br/>
        Qualquer dúvida, nos contate através dos canais abaixo.
        <br/>
        atendimento@bhs.com.br
        <br/>
        4000-1744 – opção 1
      </span>
    )
  }

  onBlurQuantity = () => {
    const { license, savedLicense, renderModalAlert, onChangeQuantity } = this.props;
    if(license.produto.tipoProduto != CONSTANTES_AMBIENTE.PRODUTO_TIPOS_PRODUTOS.ONLINESERVICESNCE){
      return;
    }

    if(license.quantidade <= 0){
      onChangeQuantity(savedLicense.quantidade, license);
      return renderModalAlert(this.msgAlertQuantityEmpty);
    }

    var dataAtual = new Date();
    var dataFinalCancelamento = new Date(license.dataLimiteCancelamento);
    if(license.quantidade < savedLicense.quantidade){
      if(dataAtual > dataFinalCancelamento && license.vigencia == "P1Y") {
        onChangeQuantity(savedLicense.quantidade, license);
        return renderModalAlert(this.msgAlertQuantityReduction);
      }
    }
  }

  
  renderModal() {
    const {
      closeModalAssinaturaForaDoPrazo,
      closeModalCancelarAssinatura,
      closeModalTermoCancelarAssinatura,
      closeModalConfirmarCancelamentoAssinatura,
      checkedPolicy,
      loadingModal
    } = this.state;

    if (closeModalCancelarAssinatura === false) {
      return (
        <ModalCancelarAssinatura
            openModal = {!closeModalCancelarAssinatura}
            onCloseModal = {this.onCloseModal.bind(this)}
            onConfirmCancelarAssinatura = {this.onConfirmCancelarAssinatura.bind(this)}
          />
      )
    } else if (closeModalTermoCancelarAssinatura === false) {
      return (
        <ModalTermoCancelamento
            openModal = {!closeModalTermoCancelarAssinatura}
            onCloseModal = {this.onCloseModal.bind(this)}
            onConfirmModal = {this.onSubmitCancelarAssinatura.bind(this)}
            policies={policiesObject}
            checkedPolicy={checkedPolicy}
            setCheckedPolicy={this.setCheckedPolicy.bind(this)}
            loadingModal={loadingModal}
          />
      )
    } else if (closeModalConfirmarCancelamentoAssinatura === false) {
      return (
        <ModalConfirmarCancelamentoAssinatura
          openModal = {!closeModalConfirmarCancelamentoAssinatura}
          onCloseModal = {this.onCloseModal.bind(this)}
        />
      )
    } else if (closeModalAssinaturaForaDoPrazo === false) {
      return (
        <ModalAssinaturaForaDoPrazo
          openModal = {!closeModalAssinaturaForaDoPrazo}
          onCloseModal = {this.onCloseModal.bind(this)}
        />
      )
    }
  }

  render() {
    const { license, onChangeQuantity, readOnly, isCheckout, handleCurrencyFormat } = this.props;

    return (
      <div className="list-box">
        {this.renderModal()}
        <div className="box-text">
          <span className="title">{license.produto.nome}</span>
          <span className="subtitle"><strong>Quantidade: </strong>
            {
              !isCheckout ? (
              <CustomQuantity
                customClass={`filter ${license.produto.tipoProduto == CONSTANTES_AMBIENTE.PRODUTO_TIPOS_PRODUTOS.ONLINESERVICESNCE && license.quantidade <= 0 ? 'inputError' : ''}`}
                onChangeFunction={onChangeQuantity}
                userLicense={license}
                defaultValue={license.quantidade}
                readOnly={readOnly}
                onBlur={this.onBlurQuantity}
              />
              ) : (
                <span>{license.quantidade}</span>
              )
            }

            {license.produto.tipoProduto == CONSTANTES_AMBIENTE.PRODUTO_TIPOS_PRODUTOS.ONLINESERVICESNCE && license.mudarQuantidadePara && license.termino && 
              <span className="box-message scheduled">
                {`Agendado para ${new Date(license.termino).toLocaleDateString('pt-BR', {timeZone: 'UTC'})} a mudança para ${license.mudarQuantidadePara} licença${license.mudarQuantidadePara > 1 ? 's' : ''}`}
              </span>
            }
          </span>
          <span className="subtitle">
            <strong>Valor unitário: </strong>
            {
              (license.precoUnitario !== undefined) ? 
              handleCurrencyFormat(license?.precoUnitario, license?.produto.moeda.Nome):
              handleCurrencyFormat(0, license?.produto.moeda.Nome)
            }
          </span>
          <span className="subtitle">
            <strong>Valor total: </strong>
            {
              (license.precoUnitario !== undefined) ? 
              handleCurrencyFormat(license?.precoUnitario * license?.quantidade, license?.produto.moeda.Nome) : 
              handleCurrencyFormat(0, license?.produto.moeda.Nome)}
          </span>
          {license?.unidade?.Vigencia && license?.unidade?.PeriodicidadeFaturamento &&
            <div className="box-validity-frequency">
              <span className="subtitle validity"><strong>Vigência - </strong>{license.unidade.Vigencia}</span>
              <span className="subtitle"><strong>Periodicidade de cobrança - </strong>{license.unidade.PeriodicidadeFaturamento}</span>                            
              {!isTenantLegacy() &&
                  <div className="subtitle position-right">
                    <a className="cursor-pointer" onClick={()=> { this.onClickCancelarAssinatura();}}>Cancelar assinatura</a>
                  </div>              
              }
            </div>
          }

          {
            (!license.assinaturaId !== false || !!license.is_new) && (
              <div className="box-message sub-modification new">
                <span>Novo</span>
              </div>
            )
          }

          {
            (!!readOnly && license.razaoStatus === 282390001) && (
              <div className="box-message sub-modification buying">
                <span>Em compra</span>
              </div>
            )
          }

          {
            !license.assinaturaId !== true && !!license.edited && (
              <div className="box-message sub-modification edited">
                <span>Editado</span>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}
