import { Button, Col, Row, Space } from "antd"
import { StyledForm } from "./styles"
import React, { useEffect, useState } from "react"
import PortalSelect from "./Components/PortalSelect";
import PortalInputText from "./Components/PortalInputText";
import PortalInputUpload from "./Components/PortalInputUpload";
import PortalDatePicker from "./Components/PortalDatePicker";
import PortalDatePeriodPicker from "./Components/PortalDatePeriodPicker";
import ObjectUtils from "utils/objects";
import PortalRadio from "./Components/PortalRadio";
import PortalCheckbox from "./Components/PortalCheckbox";

function Formulario({formReference, inputsData, ...props}){
    const [submittable, setSubmittable] = useState(false);
    const [inputs, setInputs] = useState([])
    const [isMounted, setIsMounted] = useState(true);
    const values = StyledForm.useWatch([], formReference);
    const [propsInputs, setPropsInputs] = useState({empty:null});
    const [submitButtonStyle, setSubmitButtonStyle] = useState({columnSpan: 1, itemOffset: 8, itemSpan: 16});
    const [inputSpaceSize, setInputSpaceSize] = useState("small");
    
    useEffect(()=>{
        if (props.submitButtonAlign == "center"){
            setSubmitButtonStyle({columnSpan: 24, itemOffset: 11, itemSpan: 24});
        }

        if (props.inputSpaceSize) {
            setInputSpaceSize(props.inputSpaceSize);
        }
        
        return () => {
            setIsMounted(false);
        };
    },[])
    
    useEffect(()=>{
        if(!isMounted){
            return;
        }
        if(inputsData.length > 0){
            if(!ObjectUtils.objetosSaoIguais(inputsData, propsInputs)){
                setPropsInputs(inputsData)
                GenerateInputs();
            }
        }
    },[inputsData])

    useEffect(() => {

    },[])
    
    useEffect(() => {
        ValidateSubmitable();
    }, [values]);

    const ValidateSubmitable = () => {
        formReference.validateFields({ validateOnly: true }).then(
            (values) => {
                if(!isMounted || Object.keys(values).length == 0){
                    return;
                }
                setSubmittable(true);
                if(props.onSubmitableChange != null && typeof (props.onSubmitableChange) == "function"){
                    props.onSubmitableChange(true);
                }
            }).catch(()=>{
                setSubmittable(false);
                if(props.onSubmitableChange != null && typeof (props.onSubmitableChange) == "function"){
                    props.onSubmitableChange(false);
                }
            });
    }

    const GenerateInputs = () => {
        let data = [];
        inputsData.forEach(inputData => {
            switch (inputData.input.type) {
                case 'text':
                case 'textarea':
                    data.push(<Col span={inputData.input?.colSpan ? inputData.input.colSpan : (props.inputColSpan? props.inputColSpan : 24)} key={inputData.input.name}><PortalInputText {...inputData} form={formReference}/></Col>)
                    break;
                case 'date':
                    data.push(<Col span={inputData.input?.colSpan ? inputData.input.colSpan : (props.inputColSpan? props.inputColSpan : 24)} key={inputData.input.name}><PortalDatePicker {...inputData} form={formReference}/></Col>)
                    break;
                case 'dateperiod':
                    data.push(<Col span={inputData.input?.colSpan ? inputData.input.colSpan : (props.inputColSpan? props.inputColSpan : 24)} key={inputData.dateperiod.start}><PortalDatePeriodPicker {...inputData} form={formReference}/></Col>)
                    break;
                case 'select':
                case 'selectSearch':
                    data.push(<Col span={inputData.input?.colSpan ? inputData.input.colSpan : (props.inputColSpan? props.inputColSpan : 24)} key={inputData.input.name}><PortalSelect {...inputData} form={formReference} portalOnSelectValueChange={props.portalOnSelectValueChange || null}/></Col>)
                    break;
                case 'anexo':
                    data.push(<Col span={inputData.input?.colSpan ? inputData.input.colSpan : (props.inputColSpan? props.inputColSpan : 24)} key={inputData.input.name}><PortalInputUpload {...inputData} form={formReference}/></Col>)
                    break;
                case 'radio':
                    data.push(<Col span={inputData.input?.colSpan ? inputData.input.colSpan : (props.inputColSpan? props.inputColSpan : 24)} key={inputData.input.name}><PortalRadio {...inputData} form={formReference}/></Col>)
                    break;
                case 'checkbox':
                    data.push(<Col span={inputData.input?.colSpan ? inputData.input.colSpan : (props.inputColSpan? props.inputColSpan : 24)} key={inputData.input.name}><PortalCheckbox {...inputData} form={formReference}/></Col>)
                    break;
                default:
                    break;
            }
        })
        if(isMounted){
            setInputs(data);
        }
    }

    const onFinishFailed = (value) => {
        return props.onFinishFailed(value);
    }
    const onFinish = async (value) =>{
        return props.onFinish(value)
    }
    /**
     * Limpa os campos e retorna os valores anteriores do formulário
     * @returns 
     */
    const onClear = () =>{
        let oldValues = formReference.getFieldsValue();
        formReference.resetFields();
        return props.onClear(oldValues);
    }

    return(
        <div key="formulariokey">
            <StyledForm 
                form={formReference}
                onFinish={onFinish} 
                onFinishFailed={onFinishFailed}
                onFieldsChange={props.onFieldsChange}
                onValuesChange={props.onValuesChange}
                initialValues={props.initialValues}
                disabled={props.disabled || props.readOnly}
                layout="vertical"
            >
                <Row align={"middle"}>
                    <Col span={24} flex="none">
                        {props.image ? <img src={`data:image/jpeg;base64,${props.image}`} /> : ""}
                    </Col>
                    {props.title ? 
                        <Col flex="auto">
                            <h1>{props.title}</h1>
                        </Col>
                    : ""}
                </Row>
                <Row gutter={24} xs={{ flex: '100%' }}
                    sm={{ flex: '50%' }}
                    md={{ flex: '40%' }}
                    lg={{ flex: '20%' }}
                    xl={{ flex: '10%' }}>
                        {props.inputsSpace ? 
                            <Space direction={props.inputsSpace?.direction ?? "vertical"} size={props.inputsSpace?.inputSpaceSize ?? "small"}>
                                {inputs && inputs.map(x=>x)}
                            </Space>
                        :
                            <>
                                {inputs && inputs.map(x=>x)}
                            </>
                        }
                    {props.hideSubmitButton ? null : 
                        <Col span={submitButtonStyle.columnSpan} style={{alignSelf:"center"}} className="col-submit">
                            <StyledForm.Item wrapperCol={{ offset: submitButtonStyle.itemOffset, span: submitButtonStyle.itemSpan }} >
                                <Space direction="vertical">
                                    {
                                        props.showClearButton && (
                                        <Button onClick={onClear}>
                                            Limpar
                                        </Button>)
                                    }
                                    <Button className="submitButton" type="primary" htmlType="submit" disabled={!submittable}> 
                                        {props.submitButtonMessage ?? "Confirmar"}
                                    </Button>
                                </Space>
                            </StyledForm.Item> 
                        </Col>
                    }
                </Row>
                <div>
                    {props.formErrors?.length > 0 ? (
                        props.formErrors.map((erro, index) => (
                        <span key={index} style={{ color: 'red' }}>
                            {erro}
                        </span>
                        ))
                    ) : (
                        null
                    )}
                </div>
            </StyledForm>            
        </div>
    )
}

export default Formulario