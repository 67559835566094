import React from 'react';
import { termsRules, termRules, userRulesV2 } from './routerHelpers'

/* Pages */
import Dashboard from '../pages/dashboard';
import Azure from '../pages/azure';
import MicrosoftCsp from '../pages/microsoftCsp';
import Master from '../pages/master';
import FaturamentoMaster from '../pages/faturamentoMaster';
import UserLicensesReport from '../pages/userLicensesReport';
import Licenciamento from '../pages/Licenciamento';
import SuportePremium from '../pages/suportePremium';
import AlterarTermo from '../pages/termos/editar';
import ListagemAtendimentos from 'pages/Atendimentos/Listagem';
import EdicaoAtendimentos from '../../pages/Atendimentos/Edicao';
import ListUserV2 from '../pages/usuariosV2/index.jsx';
import CriarUsuariosV2 from '../pages/usuariosV2/novo/index.jsx';
import EditarUsuariosV2 from '../pages/usuariosV2/editar/index.jsx';
import AlterarPlanoV2 from '../pages/usuariosV2/alterarPlano/index.jsx';
import TermosDeAceite from '../pages/termosDeAceite';
import Termo from '../pages/termosDeAceite/termo';
import ListagemTermo from '../pages/termos';
import AddTermo from '../pages/termos/novo';
import Empresas from '../pages/empresas';
import Cobranca from '../pages/cobranca';
import AlterarSenha from '../pages/gestaoUsuarios/alterarSenha';
import AlterarDados from '../pages/gestaoUsuarios/alterarDados';
import Cotacao from '../pages/cotacao';
import Atendimento from '../pages/Atendimento';
import { profileNavRelatoriosAzure, profileNavRelatoriosNuvemOnPremise, profileNavRelatoriosDispositivos, profileNavRelatoriosControleHoras, profileNavGerenciamentoAssinaturas, profileNavGerenciamentoUsuarios, profileNavRelatoriosM365Basico, profileNavRelatoriosM365Gerenciado, profilesGestor, profilesV2, profileGestorOuResponsavelTecnico, profileGestorOuFinanceiroCliente } from 'EstruturaAntiga/permissions/permissionsV2';
import ListagemServico from 'pages/Servicos/Listagem';
import ServicoCadatro from 'pages/Servicos/Cadastro';
import ListagemFinanceiro from 'pages/Financeiro/Listagem';

export const navGroups = [
	{ 0: 'Acesso rápido' },
	{ 1: 'Relatórios' },
	{ 2: 'Administração' }
];


export const navigation = [
	
	{
		'title': 'Atendimentos',
		'path': '/chamados',
		'shownav': true,
		'group': 0,
		'icon': 'icon-atendimentos',
		'render': () => userRulesV2() || <ListagemAtendimentos />,
		'child': [
			{
				'title': 'Editar chamado',
				'path': '/chamados/editar',
				'shownav': false,
				'render': (props) => userRulesV2() || <EdicaoAtendimentos {...props} />
			},
		],
		'refName': "refMenuAtendimentos"
	},
  	{
		'title': 'Assinaturas 365',
		'path': '/microsoft-csp',
		'shownav': true,
		'group': 0,
		'icon': 'icon-licensas',
		'profile': profileNavGerenciamentoAssinaturas,
		'render': () => userRulesV2(profileNavGerenciamentoAssinaturas) || <MicrosoftCsp />,
		'refName': "refMenuAssinaturas365"
	},
	{
		'title': 'Usuários',
		'path': '/usuarios',
		'shownav': true,
		'group': 0,
		'icon': 'icon-user',
		'profile': profileNavGerenciamentoUsuarios,
		'render': () => userRulesV2(profileNavGerenciamentoUsuarios, false, true) ||  <ListUserV2 />,
		'child': [
			{
				'title': 'Novo usuário',
				'path': '/usuarios/novo',
				'shownav': false,
				'profile': profileNavGerenciamentoUsuarios,
				'render': () => userRulesV2(profileNavGerenciamentoUsuarios, false, true) || <CriarUsuariosV2 />
			},
			{
				'title': 'Editar usuário',
				'path': '/usuarios/editar',
				'shownav': false,
				'profile': profileNavGerenciamentoUsuarios,
				'render': () => userRulesV2(profileNavGerenciamentoUsuarios, false, true) || <EditarUsuariosV2 />
			},
			{
				'title': 'Alterar plano',
				'path': '/usuarios/alterarPlano',
				'shownav': false,
				'profile': profileNavGerenciamentoUsuarios,
				'render': () => userRulesV2(profileNavGerenciamentoUsuarios, false, true) || <AlterarPlanoV2 />
			}
		],
		'refName': "refMenuUsuarios"
	},
	{
		'title': 'Solicitação de Serviços',
		'path': '/servicos',
		'shownav': true,
		'group': 0,
		'icon': 'icon-preciso-servico',
		'profile': profileGestorOuResponsavelTecnico,
		'render': () => userRulesV2(profileGestorOuResponsavelTecnico, false, false) || <ListagemServico />,
		'child': [
			{
				'title': 'Editar chamado',
				'path': '/servicos/cadastro',
				'shownav': false,
				'profile': profileGestorOuResponsavelTecnico,
				'render': (props) => userRulesV2(profileGestorOuResponsavelTecnico, false, false) || <ServicoCadatro {...props} />
			},
		],
		'refName': "refMenuSolicitacaoServicos"
	},
	{
		'title': 'Financeiro',
		'path': '/financeiro',
		'shownav': true,
		'group': 0,
		'icon': 'icon-financeiro',
		'profile': profileGestorOuFinanceiroCliente,
		'render': () => userRulesV2(profileGestorOuFinanceiroCliente, false, false) || <ListagemFinanceiro />,
		'refName': "refMenuFinanceiro"
	},

	/* Group 1 - Produtos e serviços */
	{
		'title': 'Dashboard',
		'path': '/dashboard',
		'shownav': false,
		'render': () => userRulesV2() || <Dashboard />,
	},
	{
		'title': 'Termos de aceite',
		'path': '/TermosDeAceite',
		'shownav': false,
		'render': () => termsRules() || <TermosDeAceite />,
		'child': [
			{
				'title': 'Termo',
				'path': '/TermosDeAceite/Termo',
				'shownav': false,
				'render': () => termRules() || <Termo />
			}
		]
	},
	{
		'title': 'Alterar senha',
		'path': '/AlterarSenha',
		'render': () => userRulesV2() || <AlterarSenha />
	},
	{
		'title': 'Atualizar meus dados',
		'path': '/EditarMeusDados',
		'render': () => userRulesV2() || <AlterarDados />
	},
	{
		'title': 'Cotação',
		'path': '/Cotacao',
		'render': () => userRulesV2() || <Cotacao />
	},
	{
		'title': 'Azure',
		'path': '/azure',
		'shownav': true,
		'group': 1,
		'icon': 'icon-nuvem',
		'isReport': true,
		'profile': profileNavRelatoriosAzure,
		'render': () => userRulesV2(profileNavRelatoriosAzure, false, false) || <Azure />,
		'refName': "refRelatorioAzure"
	},
	{
		'title': 'Master',
		'path': '/master',
		'shownav': true,
		'isReport': true,
		'group': 1,
		'icon': 'icon-m365',
		'profile': profileNavRelatoriosM365Gerenciado,
		'render': () => userRulesV2(profileNavRelatoriosM365Gerenciado, false, false) || <Master />,
		'refName': "refRelatorioMaster"
	},
	{
		'title': 'Faturamento Master',
		'path': '/faturamento-master',
		'shownav': true,
		'isReport': true,
		'group': 1,
		'icon': 'icon-m365',
		'profile': profileNavRelatoriosM365Gerenciado,
		'render': () => userRulesV2(profileNavRelatoriosM365Gerenciado, false, false) || <FaturamentoMaster />,
		'refName': "refRelatorioFaturamentoMaster"
	},
	{
		'title': 'Licenciamento CSP',
		'path': '/licenciamento-csp',
		'shownav': true,
		'isReport': true,
		'group': 1,
		'icon': 'icon-licensas',
		'profile': profileNavRelatoriosM365Basico,
		'render': () => userRulesV2(profileNavRelatoriosM365Basico, false, false) || <Licenciamento />,
		'refName': "refRelatorioLicenciamentoCsp"
	},
	{
		'title': 'Extrato de usuários',
		'path': '/extrato-de-usuarios',
		'shownav': true,
		'isReport': true,
		'group': 1,
		'icon': 'icon-m365',
		'profile': profileNavRelatoriosM365Gerenciado,
		'render': () => userRulesV2(profileNavRelatoriosM365Gerenciado, false, false) || <UserLicensesReport />,
		'refName': "refRelatorioExtratoUsuario"
	},
	{
		'title': 'Atendimentos',
		'path': '/atendimentos',
		'shownav': true,
		'isReport': true,
		'group': 1,
		'icon': 'icon-suporte-premium',
		'profile': profilesGestor,
		'render': () => userRulesV2(profilesGestor, false, false) || <Atendimento />,
		'refName': "refRelatorioAtendimentos"
	},
	{
		'title': 'Horas Sob Demanda',
		'path': '/suporte',
		'shownav': true,
		'isReport': true,
		'group': 1,
		'icon': 'icon-suporte-premium',
		'profile': profileNavRelatoriosControleHoras,
		'render': () => userRulesV2(profileNavRelatoriosControleHoras, false, false) || <SuportePremium />,
		'refName': "refRelatorioSuportePremium"
	},

	/* Group 2 - Administração */
	{
		'title': 'Termos de aceite',
		'path': '/termos',
		'shownav': true,
		'group': 2,
		'icon': 'icon-edit',
		'profile': [profilesV2.admGlobal],
		'render': () => userRulesV2([profilesV2.admGlobal]) || <ListagemTermo />,
		'child': [
			{
				'title': 'Adicionar Termo de aceite',
				'path': '/termos/novo',
				'shownav': false,
				'profile': [profilesV2.admGlobal],
				'render': () => userRulesV2([profilesV2.admGlobal]) || <AddTermo />
			},
			{
				'title': 'Editar Termo de aceite',
				'path': '/termos/editar',
				'shownav': false,
				'profile': [profilesV2.admGlobal],
				'render': () => userRulesV2([profilesV2.admGlobal]) || <AlterarTermo />
			}
		]
	},
	{
		'title': 'Empresas e filiais',
		'path': '/empresas',
		'shownav': true,
		'group': 2,
		'icon': 'icon-company',
		'profile': [profilesV2.admGlobal],
		'render': () => userRulesV2([profilesV2.admGlobal]) || <Empresas />
	},
	{
		'title': 'Cobrança',
		'path': '/cobranca',
		'shownav': true,
		'group': 2,
		'icon': 'icon-financial',
		'profile': [profilesV2.admGlobal],
		'render': () => userRulesV2([profilesV2.admGlobal]) || <Cobranca />
	}
]
