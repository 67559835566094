import styled from 'styled-components';
import {Form} from "antd";
export const StyledForm = styled(Form)`
  /* border: 1px solid black; */
  /* border-radius: 10px; */
  padding: 10px;
  .ant-form-item{
    margin-bottom: 8px;
  }
  
  img{
    height: 60px;
    margin-right: 20px;
  }

  #form-header{
    width: 100%;
  }
  button{
    text-align:end;
  }
  .ant-space{
    width: 100%;
  }
  .ant-radio-group{
    flex-wrap: wrap;
  }
  .ant-radio-wrapper{
    margin-top: 5px;
  }
  .col-submit .ant-form-item-control {
    margin: 0px !important;
  }
`;

export const SpinContainer = styled.div`
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
`;