import React, { Component } from 'react';
import { NotificationManager } from "react-notifications";

import MasterPage from '../../masterpages/authenticated';
import ContentBox from "../../../components/ContentBox"
import ListItens from './components/ListItens';
import Checkout from './components/Checkout';
import * as httpRequest from '../../assets/httpRequest';
import Modal from "../../components/modal/modal";
import policiesObject from './components/polices.json';
import ModalCancelarContrato from '../ModalCancelarContrato';
import ModalTermoCancelamento from '../ModalTermoCancelamento';
import Form from "EstruturaAntiga/assets/formValidate"
import postCancelarContrato from 'EstruturaAntiga/actions/contratos/postCancelarContrato';
import Button from 'EstruturaAntiga/components/form/Button';
import { isTenantNCE } from 'EstruturaAntiga/router/routerHelpers';
import ModalCancelarContratoConfirmacao from 'EstruturaAntiga/pages/ModalCancelarContratoConfirmacao';
import AssinaturasService from "../../../services/AssinaturasService"
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import PedidoCompraService from 'services/PedidoCompraService';

export default class microsoftCsp extends Form {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorInfo: '',
      loadingPage: true,
      closeModalCancelarContrato: true,
      closeModalTermoCancelarContrato: true,
      closeModalCancelarContratoConfirmacao: true,
      checkedPolicy: false,
      newLicenses: [],
      allLicenses: [],
      savedLicenses: [],
      loading: true,
      loadingModal: false,
      // managementButtonDisabled: true,
      totalMensal: 0,
      tenantName: '',
      filterText: '',
      contractMasterId: '',
      receive: [],
      pedidoCompra: [],
      timelinePosition: 0,
      newLicensesValue: 0,
      checkoutOption: 0,
      acceptedTerms: false,
      renderAlert: false,
      msgModalAlert: (()=>{}),
      changedLicenseQuantities: false,
      formContent: {
				emailaddress2: {
					nome: "E-mail alternativo",
					somenteLeitura: true,
					visible: true,
					obrigatorio: true,
					tamanho: null,
				},
				mobilephone: {
					nome: "Celular",
					somenteLeitura: true,
					visible: true,
					obrigatorio: true,
					tamanho: 50,
				}	
			},
      form: {
				emailaddress2: '',
				mobilephone: ''
			},
			hasError: {
				emailaddress2: {},
				mobilephone: {}
			},
			validations: {
				emailaddress2: { mask: 'email', required: true },
				mobilephone: { mask: 'phone', required: true }
			}
    }

    //this.onConfirmCancelarContrato = this.onConfirmCancelarContrato.bind(this);
  }

  async componentDidMount() {
    try {
      var tenantId = JSON.parse(sessionStorage.getItem("signature")).axtTenantId;
      var assinaturas = await AssinaturasService.ListarAssinaturasPorDireito(tenantId)
      if(assinaturas.length == 0){
        this.setState({
          error: true,
          errorInfo: "Nenhuma assinatura localizada para o Tenant atual."
        })
        return;
      }
      const tenant = httpRequest.userSignature().tenantName;
      const adjustLicenses = assinaturas.filter(assinatura => assinatura.produto.tipoProduto == CONSTANTES_AMBIENTE.PRODUTO_TIPOS_PRODUTOS.ONLINESERVICESNCE)
      .map(assinatura =>({
        ...assinatura,
        precoUnitario: assinatura.precoUnitario ? assinatura.precoUnitario : undefined,
      }))

      if(adjustLicenses.length == 0){
        this.setState({
          error: true,
          errorInfo: "O Portal do cliente não gerencia assinaturas Legacy. Para realizar alterações por favor entre em contato com a Central de Atendimento ou através de um Chamado."
        })
        return;
      }
      const total = adjustLicenses.reduce((acumulado, assinatura) => acumulado + ((assinatura.precoUnitario !== undefined) ? (assinatura.precoUnitario * assinatura.quantidade) : 0), 0);

      const savedLicenses = [...assinaturas];

      this.setState({
        allLicenses: adjustLicenses,
        savedLicenses: savedLicenses,
        totalMensal: this.handleCurrencyFormat(total, adjustLicenses.length ? adjustLicenses[0].produto.moeda.nome : ''),
        tenantName: tenant,
      });
    } catch (error) {
      this.setState({
        error: true,
        errorInfo: error.message
      });
    } finally {
      this.setState({
        loading: false,
        loadingPage: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.allLicenses !== this.state.allLicenses) {
      this.handleChangedLicenseQuantities();
    }
  }

  onChangeFilter = e => {
    return this.setState({
      filterText: e.target.value,
    });
  }

  calcularTotal = () => {
    const { allLicenses } = this.state;

    const total = allLicenses.length > 0 ? allLicenses.reduce((acumulado, license) => {
      const SUM = (license.precoUnitario !== undefined) ? (license.precoUnitario * license.quantidade) : 0;

      return acumulado + SUM;
    }, 0) : 0;

    this.setState({
      totalMensal:  this.handleCurrencyFormat(total, allLicenses[0].produto.moeda.Nome),
    });
  }

  onChangeQuantity = (value, userLicense, readOnly) => {
    if (readOnly) {
      return;
    }
    
    var regex = /^[0-9]+$/;
    if(value && (!regex.test(value) || (value.toString().substring(0,2) == '00'))){
      return;
    } 
      
    const { allLicenses, savedLicenses } = this.state;

    const newValues = allLicenses.map(old => {
      if (old.assinaturaId === userLicense.assinaturaId) {
        old.quantidade = value;
        old.edited = old.produto.tipoProduto == CONSTANTES_AMBIENTE.PRODUTO_TIPOS_PRODUTOS.ONLINESERVICESNCE ? 
                      savedLicenses.find(saved => saved.assinaturaId === old.assinaturaId).quantidade != value : 
                      !old.is_new;
      }
      return old;
    });

    this.calcularTotal();

    this.setState({
      allLicenses: newValues,
    });
  }

  back = e => {
    this.setState({
      timelinePosition: this.state.timelinePosition - 1,
    });
  }

  checkoutChange = (value) => {
    this.setState({
      checkoutOption: value,
    });
  }

  handleCurrencyFormat = (value, currencySymbol) => {
    value = value ? value : 0;
    currencySymbol = currencySymbol ? currencySymbol : '$';
    
    return `${currencySymbol}${value.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})} `;
  }

  renderModalAlert = (msg = '') => {
    const { renderAlert } = this.state;

    this.setState({
        renderAlert: !renderAlert,
        msgModalAlert: msg
      });
  }

  handleChangedLicenseQuantities = () => {
    const { savedLicenses, allLicenses,timelinePosition } = this.state;
    
    let changedLicenseQuantities = false;

    savedLicenses && savedLicenses.map(savedLicense => {
      const changedLicense = allLicenses.find(changedLicense => changedLicense.assinaturaId === savedLicense.assinaturaId);
      if(savedLicense.quantidade != changedLicense.quantidade && !changedLicenseQuantities){
        changedLicenseQuantities = true;
      }
    });
    
    this.setState({changedLicenseQuantities})
  }

  onSubmitCheck = async e => {
    
    const { allLicenses } = this.state;

    let continueToCheckout = true;
    allLicenses.forEach(license => {
      if (parseInt(license.produto.quantidadeMaxima) && license.quantidade > parseInt(license.produto.quantidadeMaxima)) {
        continueToCheckout = false;

        return NotificationManager.error(
          `A licença ${license.produto.nome} excedeu o limite máximo de: ${license.produto.quantidadeMaxima} licenças.`,
          "Limite de licenças excedido.",
          9000
        );
      }
    });

    if (!continueToCheckout) {
      return this.setState({
        loading: false,
      });
    }

    this.setState({
      loading: true,
    });
    //TODO: Verificado até aqui
    const contact = httpRequest.contact();

    const responseData = {
      CspLicenseOrder: [],
      SubscriptionCspQuote: []
    }
    const pedidosLicenca = allLicenses.map(license => (
      {
        assinatura: {assinaturaId: license.assinaturaId},
        quantidade: license.quantidade
      }
    ));

    const axtTenantId = JSON.parse(sessionStorage.getItem("signature")).axtTenantId;
    const responseNova = await PedidoCompraService.CalcularNovoValor(axtTenantId, pedidosLicenca).then(r=>r).catch(error=>{
      this.setState({
        loading: false,
      });
      return NotificationManager.error(
        error.response.data.erros[0],
        "Não foi possível verificar as alterações.",
        9000
      );
    })
    if (!responseNova) {
      return;
    }

    const pedidoCompra = {
      responsavelId: contact.idContact,
      tenantId: axtTenantId,
      pedidosLicenca:pedidosLicenca
    }



    this.setState({
      newLicensesValue: responseNova,
      loading: false,
      timelinePosition: 1,
      receive: responseData,
      pedidoCompra: pedidoCompra
    });
  }

  onSubmitReceive = async e => {
    this.setState({
      loading: true,
      timelinePosition: 2,
    });

    const { receive, allLicenses, pedidoCompra } = this.state;

    let licenseData =  {...receive};
    let dadosCompra = {...pedidoCompra};

    if(allLicenses[0].produto.tipoProduto == CONSTANTES_AMBIENTE.PRODUTO_TIPOS_PRODUTOS.ONLINESERVICESNCE){
      licenseData = {
        ...receive, 
        termosDeAceite: JSON.stringify(policiesObject)
      }
      dadosCompra.termosAceite = JSON.stringify(policiesObject)
    }
    const axtTenantId = JSON.parse(sessionStorage.getItem("signature")).axtTenantId;

    const response = await PedidoCompraService.EfetuarCompraLicencas(axtTenantId, dadosCompra).then(response => true).catch(error=>{
      this.setState({
        loading: false,
        timelinePosition: 1,
      });

      return NotificationManager.error(
        error?.response?.data?.erros[0],
        "Não foi possível realizar a solicitação.",
        9000
      );
    })

    if (!response) {
      return;
    }

    NotificationManager.success(
      'Sua solicitação já está em andamento. Este processo poderá levar alguns minutos. Você receberá um e-mail de confirmação.',
      'Solicitação enviada com sucesso!',
      9000
    );

    this.setState({
      loading: false,
      timelinePosition: 2,
    });
  }

  onClickCancelarModalContrato = () => {
		this.setState({ closeModalCancelarContrato: true });
		this.setState({ closeModalTermoCancelarContrato: true });
	}

  onConfirmCancelarContrato = () => {
    this.setState({ closeModalCancelarContrato: true });
		this.setState({ closeModalTermoCancelarContrato: false });
	}

  onSuccessCancelarContrato = () => {
    this.setState({ closeModalCancelarContratoConfirmacao: false });
	}

  setCheckedPolicy = (value) => {
		this.setState({ checkedPolicy: value });
	}

  onCloseModal = () => {
		this.setState({ checkedPolicy: false });
    this.onClickCancelarModalContrato();
	}

  onCloseModalCancelarContratoConfirmacao = () => {
    this.setState({ closeModalCancelarContratoConfirmacao: true });
	}

  onSubmitCancelarContrato = async (cancelarContratoData) => {
    this.setState({ loadingModal: true });
    await postCancelarContrato(cancelarContratoData)
        .then(() => {
          this.onSuccessCancelarContrato();
        })
        .catch((error) => {
          NotificationManager.error(
            error.response?.data?.mensagem ?? 'Erro inesperado ao tentar cancelar o contrato. Favor entrar em contato com o nosso atendimento.',
            error.response?.data?.titulo ?? 'Cancelar Contrato',
            5000);
        })
        .finally(() => {
          this.onCloseModal();
          this.setState({ loadingModal: false });
        });
  }

  renderTitle = () => {
    return (
      <div className="title-with-action">
        <h1>Assinaturas Microsoft CSP</h1>
        {isTenantNCE() && 
          <Button 
            type='button'
            className="btn btn-danger"
            onClick={() => this.setState({ closeModalCancelarContrato : false })}
            loading={this.state.loadingPage || this.state.loadingModal}
          >
            Cancelar Contrato
          </Button>
        }
      </div>
    )
  }

  renderModal() {
    const {
      closeModalCancelarContrato,
      closeModalTermoCancelarContrato,
      closeModalCancelarContratoConfirmacao,
      checkedPolicy,
      loadingModal
    } = this.state;

    if (closeModalCancelarContrato === false) {
      return (
        <ModalCancelarContrato
            openModal = {!closeModalCancelarContrato}
            onCloseModal = {this.onCloseModal.bind(this)}
            onConfirmCancelarContrato = {this.onConfirmCancelarContrato.bind(this)}
          />
      )
    } else if (closeModalTermoCancelarContrato === false) {
      return (
        <ModalTermoCancelamento
            openModal = {!closeModalTermoCancelarContrato}
            onCloseModal = {this.onCloseModal.bind(this)}
            onConfirmModal = {this.onSubmitCancelarContrato.bind(this)}
            policies={policiesObject}
            checkedPolicy={checkedPolicy}
            setCheckedPolicy={this.setCheckedPolicy.bind(this)}
            loadingModal={loadingModal}
          />
      )
    } else if (closeModalCancelarContratoConfirmacao === false) {
      return (
        <ModalCancelarContratoConfirmacao
            openModal = {!closeModalCancelarContratoConfirmacao}
            onCloseModal = {this.onCloseModalCancelarContratoConfirmacao.bind(this)}
          />
      )
    }
  }

  render() {
    const {
      error,
      errorInfo,
      loadingPage,
      allLicenses,
      savedLicenses,
      assinaturasSalvas,
      loading,
      totalMensal,
      tenantName,
      filterText,
      timelinePosition,
      // rateio,
      checkoutOption,
      newLicensesValue,
      contractMasterId,
      acceptedTerms,
      renderAlert,
      msgModalAlert,
      changedLicenseQuantities,
    } = this.state;

    if(error){
      return (
        <MasterPage>
          {this.renderTitle()}
          <div>
            <h2>{errorInfo}</h2>
          </div>
          
        </MasterPage>
      )
    }

    if(loadingPage){
      return (
        <MasterPage>
          {this.renderTitle()}
          <div className="small-loading">
            <div className="simple-loading"></div>
          </div>
        </MasterPage>
      )
    }

    return (
      <MasterPage>
        {this.renderTitle()}

        <div>
          <h2>{tenantName}</h2>

          <div className={`timeline ${timelinePosition === 1 && 'half'} ${timelinePosition === 2 && 'complete'}`}>
            <span className={timelinePosition === 0 ? 'timeline-dot active' : 'timeline-dot'}></span>
            <span className={timelinePosition === 1 ? 'timeline-dot active' : 'timeline-dot'}></span>
            <span className={timelinePosition === 2 ? 'timeline-dot active' : 'timeline-dot'}></span>
          </div>
        </div>

        {
          timelinePosition === 0 && (
            <ContentBox className="list-container">
              <div className="list-items user-list">
                <div className="list-header">
                  <div className="filter-box">
                    <input
                      type="text"
                      className="filter"
                      placeholder="Filtrar por assinatura"
                      value={filterText}
                      onChange={e => this.onChangeFilter(e)}
                    />
                    <i className="icon icon-search"></i>
                  </div>
                </div>

                <ListItens
                  licenses={allLicenses}
                  savedLicenses={savedLicenses}
                  assinaturas={assinaturasSalvas}
                  loading={loading}
                  filter={filterText}
                  onChangeQuantity={this.onChangeQuantity}
                  // rateio={rateio}
                  contractMasterId={contractMasterId}
                  calcularTotal={this.calcularTotal}
                  handleCurrencyFormat={this.handleCurrencyFormat}
                  renderModalAlert={this.renderModalAlert}
                  renderAlert={renderAlert}
                  // onChangeApportionment={this.onChangeApportionment}
                />

                <div className="mensal-price">
                  <p>Valor total mensal: <strong>{totalMensal}</strong></p>
                </div>
              </div>
            </ContentBox>
          )
        }

        {
          timelinePosition === 1 && (
            <Checkout
              licenses={allLicenses}
              newLicensesValue={newLicensesValue}
              totalMensal={totalMensal}
              // rateio={rateio}
              loading={loading}
              checkoutOption={checkoutOption}
              acceptedTerms={acceptedTerms}
              acceptTerms={() => this.setState({ ...this.state, acceptedTerms: !acceptedTerms })}
              handleCurrencyFormat={this.handleCurrencyFormat}
              policy={policiesObject}
            />
          )
        }

        {
          (timelinePosition === 2 && loading) && (
            <div className="small-loading">
              <div className="simple-loading"></div>
            </div>
          )
        }

        {
          (timelinePosition === 2 && !loading) && (
            <ContentBox className="list-container">
              <div className="list-items user-list receive-finish">
                <p>Sua solicitação já está em andamento. Este processo poderá levar alguns minutos. Você receberá um e-mail de confirmação.</p>

                <button className="btn btn-default" onClick={() => window.location.reload()}>Retornar ao início</button>
              </div>
            </ContentBox>
          )
        }

        {
          timelinePosition < 2 && (
            <div className="actions csp-actions">
              {
                timelinePosition > 0 && (
                  <button className="btn" onClick={() => this.back()}>Voltar</button>
                )
              }
              <button
                id="btnEditar"
                onClick={() => timelinePosition === 0 ? this.onSubmitCheck() : this.onSubmitReceive()} 
                disabled={
                  loading || 
                  allLicenses.length === 0 || 
                  (timelinePosition === 1 && allLicenses[0].produto.tipoProduto == CONSTANTES_AMBIENTE.PRODUTO_TIPOS_PRODUTOS.ONLINESERVICESNCE && !acceptedTerms) 
                  || (allLicenses[0].produto.tipoProduto == CONSTANTES_AMBIENTE.PRODUTO_TIPOS_PRODUTOS.ONLINESERVICESNCE && (allLicenses.filter(license => license.quantidade <= 0).length || !changedLicenseQuantities))
                }
                className="btn btn-default"
              >Confirmar</button>
            </div>
          )
        }

        {renderAlert &&
          <Modal open={renderAlert} close={() => this.renderModalAlert()}>
            <div>
              <div className="text-modal">
                <div>
                  {msgModalAlert()}
                </div>
              </div>
              <div className="btn-modal">
                <button
                  onClick={() => this.renderModalAlert()}
                  className="btn btn-default"
                >
                  Ok
                </button>
              </div>
            </div>
          </Modal>
        }
        {this.renderModal()}
      </MasterPage>
    );
  }
}
