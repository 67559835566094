import React, { Component } from 'react';

import Items from './Items';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';

export default class components extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderComprar: false,
      filteredLicenses: [],
      filtering: false,
      filterText: ''
    }
  }

  componentDidMount() {
    const { licenses, filter } = this.props;
    this.setState({
      filteredLicenses: licenses,
      filterText: filter,
      filtering: filter !== '' ? true : false
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      const { licenses, filter } = this.props;
      const filterLicenses = licenses.length > 0 ? licenses.filter(license => license.produto.nome.toLowerCase().indexOf(filter.toLowerCase()) !== -1) : [];
        
      this.setState({
        filteredLicenses: filterLicenses,
        filterText: filter,
        filtering: filter !== '' ? true : false
      });
    }
  }

  render() {
    const { filteredLicenses, filtering } = this.state;
    const { 
      licenses,
      savedLicenses,
      assinaturasSalvas,
      loading, 
      onChangeQuantity,
      calcularTotal,
      companies,
      isCheckout,
      contractMasterId,
      handleCurrencyFormat,
      renderModalAlert,
      renderAlert,
    } = this.props;

    if (loading) {
      return (
        <div className="small-loading">
          <div className="simple-loading"></div>
        </div>
      );
    } else if (filtering) {
      return (
        <div>
        {
          filteredLicenses.length > 0 ? filteredLicenses.map(license => (
            <Items 
              key={license.assinaturaId ? license.assinaturaId : license.produto.produtoId} 
              license={license}
              savedLicense={savedLicenses?.find(savedLicense => savedLicense?.assinaturaId === license?.assinaturaId)}
              assinaturaSalva={assinaturasSalvas?.find(assinatura => assinatura?.assinaturaId === license?.assinaturaId)}
              onChangeQuantity={onChangeQuantity}
              readOnly={license.razaoStatus === CONSTANTES_AMBIENTE.ASSINATURAS_RAZAO_STATUS.EM_COMPRA || license.precoUnitario === undefined || license.precoUnitario === 0 || !!isCheckout ? true : false}
              companies={companies}
              calcularTotal={calcularTotal}
              contractMasterId={contractMasterId}
              handleCurrencyFormat={handleCurrencyFormat}
              renderModalAlert={renderModalAlert}
              renderAlert={renderAlert}
            />
          )) : (
            <span className="sem-assinatura">Nenhuma assinatura encontrada.</span>
          )
        }
        </div>
      );
    } else {
      return (
        <div>
          {
            licenses.length > 0 ? licenses.map(license => (
              <Items 
                key={license.assinaturaId ? license.assinaturaId : license.produto.produtoId} 
                license={license}
                savedLicense={savedLicenses?.find(savedLicense => savedLicense?.assinaturaId === license?.assinaturaId)}
                onChangeQuantity={onChangeQuantity}
                readOnly={license.razaoStatus === 282390001 || license.precoUnitario === undefined || license.precoUnitario === 0 || !!isCheckout ? true : false}
                companies={companies}
                calcularTotal={calcularTotal}
                isCheckout={isCheckout}
                contractMasterId={contractMasterId}
                handleCurrencyFormat={handleCurrencyFormat}
                renderModalAlert={renderModalAlert}
                renderAlert={renderAlert}
              />
            )) : (
              <span className="sem-assinatura">Você não possui nenhuma assinatura.</span>
            )
          }
        </div>
      );
    }    
  }
}
