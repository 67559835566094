import { withRouter  } from 'react-router-dom'
import Logo from 'assets/images/logo.png';
import { Row, Col} from "antd";
import '../style.scss';

function PesquisaSatisfacaoConclusao({ history, ...props}){
    return(
        <Row justify={"center"} className="pesquisa-satisfacao">
            <Col xs={{ flex: '90%' }}
                sm={{ flex: '80%' }}
                md={{ flex: '70%' }}
                lg={{ flex: '70%' }}
                xl={{ flex: '70%' }}
            >
                <img src={Logo} alt="BHS" />
                <br/>
                <h2>Agradecemos por preencher nossa pesquisa de satisfação.</h2>
                <h3>Sua opinião é fundamental para que possamos oferecer um serviço cada vez melhor.</h3>
                <h3>Para sugestões ou reclamações, sinta-se à vontade para nos acionar: <a href="mailto:sucessodocliente@bhs.com.br">sucessodocliente@bhs.com.br</a></h3>
            </Col>
        </Row>
    )
}

export default withRouter(PesquisaSatisfacaoConclusao)