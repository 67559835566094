import React, { useId } from 'react';
import { ClearCacheProvider } from 'react-clear-cache';
import ReactDOM from 'react-dom';
import store from './EstruturaAntiga/store/configureStore';
import App from './App';
import { unregister } from './EstruturaAntiga/registerServiceWorker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { runWithAdal } from 'react-adal';
import { authContext } from './EstruturaAntiga/adalConfig';
import { DrawerProvider } from 'context/DrawerContext';

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });
const rootElement = document.getElementById('root');

const DO_NOT_LOGIN = true;

runWithAdal(authContext, () => {
  ReactDOM.render(
    <ClearCacheProvider duration={3600000}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
            <DrawerProvider>
              <App />
            </DrawerProvider>
        </ConnectedRouter>
      </Provider>
    </ClearCacheProvider>,
    rootElement
  );
  unregister();
}, DO_NOT_LOGIN);
